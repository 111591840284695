const prescriptionPhotos = {
    namespaced: true,
    state:{
        //图片选中index(位置)
        SelectedImageIndex: -1,
        //图片文件列表
        UploadImages: [],
        //从编辑页面返回图片页面
        BackToCameraPage: false
    },
    getters:{

    },
    mutations:{
        SetSelectedImageIndex(state,value){
            state.SelectedImageIndex = value;
        },
        SetUploadImages(state,value){
            state.UploadImages = value;
        },
        SetBackToCameraPage(state,value){
            state.BackToCameraPage = value;
        }
    },
    actions:{
        
    }
}
export default prescriptionPhotos