<template>
  <router-view/>
</template>

<script>
  import axios from 'axios'
  import request from '../src/util/request'
  export default{
    name: 'app',
    mounted(){
      this.$router.push('/login')
    }
  }

</script>

<style lang="less">
    input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    input::-webkit-outer-spin-button{
        -webkit-appearance: none !important;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
</style>
