import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//装载element-plus组件包
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'

//装载异步请求axios包
import axios from 'axios'

import '@/css/common.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

let app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
  

//APP根组件
app.use(store).use(router).use(ElementPlus,{locale}).mount('#app')
