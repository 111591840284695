import { logout,login } from "@/api/request";
import storage from "@/util/storage";
import LoginViewVue from "@/views/Login/LoginView.vue";

const user = {
    namespaced: true,
    state: {
        username: '',
        nickname: '',
        token: '',
        personname:'',
        age:'',
    },
    getters: {
        islogin(state){
            //判断是否已登录
            return state.username !== '' && state.token !== '';
        }
    },
    mutations: {
        save_token(state,value){
            state.token = value;
        },
        setpersonname(state,value){
            state.personname = value;
        },
        setage(state,value){
            state.age = value;
        }
    },
    actions: {
        LOGIN({commit},user){
            return new Promise(function(resolve){
                login(user).then(res => {
                    commit("save_username",res.data.username);
                    commit("save_token",res.data.token);
                    storage.saveTokenStorage("token",res.data.token);
                    resolve(res);
                })
            })
        },
        LOGOUT({commit}){
            return new Promise(function(resolve){
                logout().then(res => {
                    commit("save_username","");
                    commit("save_token","");
                    storage.remove("token");
                    resolve(res);
                })
            })
        },
        RECOVERY_USER({commit}){
            let loginUser = storage.getTokenStorage("token");
            if(loginUser){
                commit("save_token",loginUser);
            }
        }
    },
}
export default user