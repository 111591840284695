import {request,request2,request3,request4} from "@/api";

export function test(data){
    return request({
        url: 'login',
        method: 'post',
        data : data
    })
}

export function login(data){
    return request({
        url: 'login',
        method: 'post',
        data : data
    })
}

export function logout(data){
    return request({
        url: 'logout',
        method: 'post',
        data: data
    })
}

export function loadbasic(data){
    return request({
        url: 'loadbasic',
        method: 'get',
        data: data
    })
}

export function uploadimage(data){
    return request2({
        url: '',
        method: 'post',
        data: data
    })
}

export function medinter(data){
    return request({
        url: 'medinter',
        method: 'post',
        data: data
    })
}

export function medinterdetail(data){
    return request4({
        url: '/query/composition_composition_interaction_fixed_by_zf',
        method: 'post',
        data: data
    })
}

export function getpatientid(data){
    return request({
        url: 'getid',
        method: 'post',
        data: data
    })
}

export function searchpatients(data){
    return request({
        url: 'get_patient_info',
        method: 'post',
        data: data,
    })
}

export function searchpatientrecipes(data){
    return request({
        url: 'get_record_by_pid',
        method: 'post',
        data: data,
    })
}

export function searchconsult(data){
    return request({
        url: 'get_consult_by_patient_id',
        method: 'post',
        data: data,
    })
}

export function searchfeedbacks(data){
    return request({
        url: 'get_feedback_by_record_id',
        method: 'post',
        data: data,
    })
}

export function replycallback(data){
    return request({
        url: 'callback',
        method: 'post',
        data: data,
    })
}

export function replyconsult(data){
    return request({
        url: 'consult_callback',
        method: 'post',
        data: data,
    })
}

export function pdf(data){
    return request3({
        url: '',
        method: 'post',
        data: data
    })
}

export function saverecipe(data){
    return request({
        url: 'uploadtxt',
        method: 'post',
        data: data
    })
}

export function phonecheck(data){
    return request({
        // url: 'get_latest_record_by_phone',
        url: 'get_latest_record_by_phone_fixed_by_zf',
        method: 'post',
        data: data
    })
}