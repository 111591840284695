import { createStore } from 'vuex'

import moduleA from '@/store/modules/moduleA'
import moduleB from '@/store/modules/moduleB'
import moduleC from '@/store/modules/moduleC'

import actions from '@/store/actions'
import mutations from '@/store/mutations'
import userModule from './modules/user'
import medinfosModule from './modules/medinfos'
import prescriptionModule from './modules/prescription'
import prescriptionPhotosModule from './modules/prescriptionPhotos'
import scheduleModule from './modules/schedule'
import editreportModule from './modules/editreport'
import patientOrRecipesModule from './modules/patientOrRecipes'
import prescriptionForSendModule from './modules/prescriptionForSend'

export default createStore({
  //相当于一个data,全局所有组件都能访问
  state: {},
  getters: {},
  //类似flutter的provider的set方法
  mutations,
  actions,
  modules: {
    a: moduleA,
    b: moduleB,
    c: moduleC,
    user: userModule,
    medinfos: medinfosModule,
    prescription: prescriptionModule,
    prescriptionForSend: prescriptionForSendModule,
    prescriptionphotos: prescriptionPhotosModule,
    schedule: scheduleModule,
    editreport: editreportModule,
    patientorrecipes: patientOrRecipesModule,
  }
})
