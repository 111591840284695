import axios from 'axios'

const service = axios.create({
    baseURL: "", //根请求地址
    timeout: 5000
})

service.interceptors.request.use(
    config => {
        config.headers['arg1'] = "arg1Value";
    },
    error => {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        //拦截到成功的数据
        return response.data;
    },
    error => {
        return Promise.reject(error);
    }
)

export default service;