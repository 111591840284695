const patientOrRecipes = {
    namespaced: true,
    state: {
        searchpatientdatas:[],
        pagetotal: 0,
        pagesize: 10,
        currentpage: 1,
        patientrecipes: [],
        patientconsults: [],
        patientrecipeinfo: new Map,
        patientfeedbackcards:[],
    },
    getters: {

    },
    mutations: {
        setSearchPatientDatas(state,value){
            state.searchpatientdatas = value;
        },
        setPageTotal(state,value){
            state.pagetotal = value;
        },
        setPageSize(state,value){
            state.pagesize = value;
        },
        setCurrentPage(state,value){
            state.currentpage = value;
        },
        setPatientRecipes(state,value){
            state.patientrecipes = value;
        },
        setPatientConsults(state,value){
            state.patientconsults = value;
        },
        setPatientRecipeInfo(state,value){
            state.patientrecipeinfo = value;
        },
        setPatientFeedbackCards(state,value){
            state.patientfeedbackcards = value;
        },
    },
    actions: {

    },
}
export default patientOrRecipes