const moduleB = {
    state: {
        return: {
          count: 0,
        }
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
}
export default moduleB