<template>
    <el-container>
      <el-header style="background-color:#4fa7ff;">
        <el-row>
          <el-col :span="16">
            <div style="display:flex;align-items:center;height: 100%;">
              <el-icon color="white" :size="40" style="padding: 10px;"><FirstAidKit /></el-icon>
              <!-- <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;font-weight: bold;" >用药时刻编排助手</label> -->
              <!-- <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;font-weight: bold;" >药学监护仪</label> -->
              <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;font-weight: bold;" >用药助手——临床用药指导和辅助决策软件v1.0.0</label>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="text-align: right;">
            <div style="display:flex;align-items:center;height: 100%;width: 110px;float: right;">
              <el-icon color="white" :size="40" style="padding: 10px;cursor: pointer;" @click="dologout">
                <SwitchButton/>
              </el-icon>
              <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;cursor: pointer;" @click="dologout">
                退出
              </label>
            </div>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside style="width: 160px;">
          <el-menu router="true"  :default-active="this.$route.path" text-color="black" style="height:100%">
            <el-menu-item index="/PrescriptionPhoto" @click="GotoTabsView('PrescriptionPhoto')">
              <el-icon :size="30"><Picture /></el-icon>
              <span class="menuitemtextclass">处方图片</span>
            </el-menu-item>
            <el-menu-item index="/PrescriptionCheck" @click="GotoTabsView('PrescriptionCheck')">
              <el-icon :size="30">
                <document />
              </el-icon>
              <!-- <span class="menuitemtextclass">处方审核</span> -->
              <span class="menuitemtextclass">处方确定</span>
            </el-menu-item>
            <el-menu-item index="/ScheduleAdjustment" @click="GotoTabsView('ScheduleAdjustment')">
              <el-icon :size="30"><Clock /></el-icon>
              <!-- <span class="menuitemtextclass">调整时间</span> -->
              <span class="menuitemtextclass">用药分析</span>
            </el-menu-item>
            <el-menu-item index="/EditReport" @click="GotoTabsView('EditReport')">
              <el-icon :size="30"><Edit /></el-icon>
              <!-- <span class="menuitemtextclass">编辑报告</span> -->
              <span class="menuitemtextclass">报告生成</span>
            </el-menu-item>
            <el-menu-item index="/PatientsOrRecipes" @click="GotoTabsView('PatientsOrRecipes')">
              <el-icon :size="30"><Search /></el-icon>
              <span class="menuitemtextclass">患者&处方</span>
            </el-menu-item>
          </el-menu>       
        </el-aside>
        <el-main style="padding: 0 !important;margin: 5px;">
          <!-- 进行路由跳转的坑 -->
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
</template>

<script>
import {useRouter,useRoute} from 'vue-router'
import { useStore } from 'vuex'
import {logout} from '@/api/request.js'
import storage from '@/util/storage.js'
import { ElMessage } from 'element-plus';

let store = useStore();
//组合式API

//一个用来做路由跳转，一个用来获取路由信息，是路由实例。
// const router = useRouter();
// const route = useRoute();

let GotoTabsView = name => {
  router.push({name:name})
}

let dologout = function(){
  // store.dispatch('LOGOUT').then(res=>{
  //   console.log(res);
  //   useRouter.push({name: 'login'});
  // });
  let token = storage.getTokenStorage("token");
  let username = storage.getTokenStorage("username");
  let data = {name: username,token: token};
  try {
    logout(JSON.stringify(data)).then(res => {
      console.log(res);
      if (res.data['code'] === 200) {
        ElMessage({
          message: res.data["message"],
          type: 'success',
        })
        storage.localremove("username");
        storage.localremove("token");
      } else {
        ElMessage({
          message: res.data["message"],
          type: 'warning',
        })
      }
    })
  } catch (error) {
    console.log("注销时连接服务端失败");
    console.log(error);
  }
  
}
export default {
  created(){
    this.$router.push({name:"PrescriptionPhoto"})
  },
  methods:{
    GotoTabsView(name){
      this.$router.push({name:name});
    },
    dologout(){
      let token = storage.getTokenStorage("token");
      let username = storage.getTokenStorage("username");
      let tempdata = {name: username,token: token};
      try {
        logout(JSON.stringify(tempdata)).then(res => {
          console.log(res);
          if (res.data['code'] === 200) {
            ElMessage({
              message: res.data["message"],
              type: 'success',
            })
            storage.localremove("username");
            storage.localremove("token");
            this.$router.push({name:"login"})
          } else {
            ElMessage({
              message: res.data["message"],
              type: 'warning',
            })
          }
        })
      } catch (error) {
        console.log("注销时连接服务端失败");
        console.log(error);
      }
    }
  }
}
</script>
<style scoped>
  .el-container{
    height: 100%;
  }
  .el-aside{
    background-color:#4fa7ff;
  }
  .el-menu-item{
    height: 20%;
  }
  .menuitemtextclass{
    font-weight: bold;
    font-size: large;
  }
</style>