const editreport = {
    namespaced: true,
    state: {
        clearorload: false,
        hzjy: "",
        jbjy: "",
        jj: "",
        zysx: "",
        spxhzy: "",
        jbxhzy: "",
    },
    getters: {

    },
    mutations: {
        setClearOrLoad(state,value){
            state.clearorload = value;
        },
        sethzjy(state,value){
            state.hzjy = value;
        },
        setjbjy(state,value){
            state.jbjy = value;
        },
        setjj(state,value){
            state.jj = value;
        },
        setzysx(state,value){
            state.zysx = value;
        },
        setspxhzy(state,value){
            state.spxhzy = value;
        },
        setjbxhzy(state,value){
            state.jbxhzy = value;
        },
    },
    actions: {

    },
}
export default editreport