import axios from 'axios'
import storage from '@/util/storage.js'

// const request = axios.create({
//     baseURL: 'https://www.hnrongguan.com.cn/yaoxue/user/',
//     timeout: 10000,
//     headers: {'Content-Type':'application/json;charset=utf-8'}
// })

// const request2 = axios.create({
//     baseURL: 'http://120.77.173.120:8089/api/tr-run',
//     timeout: 10000,
//     headers: {'Content-Type':'application/json;charset=utf-8'}
// })

// const request3 = axios.create({
//     baseURL: 'http://120.77.173.120:12333/api/json2pdf',
//     timeout: 60000,
//     headers: {'Content-Type':'application/json;charset=utf-8'}
// })

const request = axios.create({
    baseURL: '/yaoxue/user/',
    timeout: 0,
    headers: {'Content-Type':'application/json;charset=utf-8'}
})

const request2 = axios.create({
    baseURL: '/api/tr-run',
    timeout: 10000,
    headers: {'Content-Type':'application/json;charset=utf-8'}
})

const request3 = axios.create({
    baseURL: '/api/json2pdf',
    timeout: 60000,
    headers: {'Content-Type':'application/json;charset=utf-8'}
})

const request4 = axios.create({
    baseURL: '/yaoxue',
    timeout: 10000,
    headers: {'Content-Type':'application/json;charset=utf-8'}
})

//拦截器配置
request.interceptors.request.use(function (config){
    //在header中添加token数据作为后台验证码
    if(storage.getTokenStorage("token")){
        config.headers['authorization'] = storage.getTokenStorage("token");
    }
    return config;
},function(error){
    return Promise.reject(error);
});
request2.interceptors.request.use(function (config){
    //在header中添加token数据作为后台验证码
    if(storage.getTokenStorage("token")){
        config.headers['authorization'] = storage.getTokenStorage("token");
    }
    return config;
},function(error){
    return Promise.reject(error);
});
request3.interceptors.request.use(function (config){
    //在header中添加token数据作为后台验证码
    if(storage.getTokenStorage("token")){
        config.headers['authorization'] = storage.getTokenStorage("token");
    }
    return config;
},function(error){
    return Promise.reject(error);
});
request4.interceptors.request.use(function (config){
    //在header中添加token数据作为后台验证码
    if(storage.getTokenStorage("token")){
        config.headers['authorization'] = storage.getTokenStorage("token");
    }
    return config;
},function(error){
    return Promise.reject(error);
});

export {request,request2,request3,request4};