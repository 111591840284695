const medinfos = {
    namespaced: true,
    state: {
        medinfos: [],
        isCommit: false,
        mednameshortcut: new Map(),
        medselected: [],
        suggestions: [],
        //药品相互作用查询数据
        medinterlist: [],

        medtimesin30: [],
    },
    getters: {

    },
    mutations: {
        setMedinfos(state,value){
            state.medinfos = value;
        },
        setIsCommit(state,value){
            state.isCommit = value;
        },
        setMednameshortcut(state,value){
            state.mednameshortcut = value;
        },
        setMedselected(state,value){
            state.medselected = value;
        },
        setSuggestions(state,value){
            state.suggestions = value;
        },
        setMedinterlist(state,value){
            state.medinterlist = value;
        },
        setMedtimesin30(state,value){
            state.medtimesin30 = value;
        }
    },
    actions: {

    },
}
export default medinfos