const prescriptionForSend = {
    namespaced: true,
    state: {
        //医院名称
        hospital: '',
        //处方种类
        type: '',
        //处方有效期
        valid_period: '',
        //门诊号
        clinic_id: '',
        //科室
        section: '',
        //处方日期
        date: '',
        //处方时间
        time: '',
        //姓名
        name: '',
        //性别
        gender: '',
        //出生日期
        birthday: '',
        //年龄
        age: '',
        //临床诊断
        diagnosis: '',
        //医师
        doctor: '',
        //药方
        med_recipe: [],

        //籍贯
        address: '',
        //手机号
        telephone: '',
        //身高
        stature: '',
        //体重
        weight: '',

        addresslist: [],

        //患者id
        uid: -1,
    },
    getters: {
    },
    mutations: {
        setpersonname(state,value){
            state.personname = value;
        },
        setName(state,value){
            state.name = value;
        },
        setGender(state,value){
            state.gender = value;
        },
        setAge(state,value){
            state.age = value;
        },
        setBirthday(state,value){
            state.birthday = value;
        },
        setDiagnosis(state,value){
            state.diagnosis = value;
        },
        setHospital(state,value){
            state.hospital = value;
        },
        setClinicId(state,value){
            state.clinic_id = value;
        },
        setSection(state,value){
            state.section = value;
        },
        setDoctor(state,value){
            state.doctor = value;
        },
        setDate(state,value){
            state.date = value;
        },
        setTime(state,value){
            state.time = value;
        },
        setAddress(state,value){
            state.address = value;
        },
        setTelephone(state,value){
            state.telephone = value;
        },
        setStature(state,value){
            state.stature = value;
        },
        setWeight(state,value){
            state.weight = value;
        },
        setMedRecipe(state,value){
            state.med_recipe = value;
        },
        setPrescription(state,value){
            state.name = value.name;
            state.gender = value.gender;
            state.age = value.age;
            state.birthday = value.birthday;
            state.diagnosis = value.diagnosis;
            state.hospital = value.hospital;
            state.clinic_id = value.clinic_id;
            state.section = value.section;
            state.doctor = value.doctor;
            state.date = value.date;
            state.time = value.time;
            state.med_recipe = value.med_recipe;
            state.address = value.address;
            state.telephone = value.telephone;
            state.stature = value.stature;
            state.weight = value.weight;
        },
        setUid(state,value){
            state.uid = value;
        },
        setAddressList(state,value){
            state.addresslist = value;
        }
    },
    actions: {
    },
}
export default prescriptionForSend