import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/Home/HomeView.vue'
import LoginView from '@/views/Login/LoginView.vue'
import store from '@/store'
import storage from '@/util/storage'

//路由配置
const routes = [
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/PrescriptionPhoto',
        name: 'PrescriptionPhoto',
        component: () => import('@/views/Tabs/PrescriptionPhoto.vue')
      },
      {
        path: '/PrescriptionCheck',
        name: 'PrescriptionCheck',
        component: () => import('@/views/Tabs/PrescriptionCheck.vue')
      },
      {
        path: '/ScheduleAdjustment',
        name: 'ScheduleAdjustment',
        component: () => import('@/views/Tabs/ScheduleAdjustment.vue')
      },
      {
        path: '/EditReport',
        name: 'EditReport',
        component: () => import('@/views/Tabs/EditReport.vue')
      },
      {
        path: '/PatientsOrRecipes',
        name: 'PatientsOrRecipes',
        component: () => import('@/views/Tabs/PatientsOrRecipes.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 全局的路由守卫，会在每次路由进行跳转的时候执行
router.beforeEach((to,from) => {
  console.log(to);
  console.log(from);
  //1 如果去的是登录页面就放行
  //2 检查是否登录，如果已登录就放行
  //3 没有登录就跳转到登录页面
  if(to.name === "login"){
    return true;
  }
  console.log(store.getters.islogin);
  if(!store.getters.islogin){
    // 去storage看有没有token，如果也没有就去登录页面
    if(!storage.getTokenStorage("token")){
      router.push({name:'login'});
    } else {
      store.dispatch("user/RECOVERY_USER");
    }
  }
  return true
})

export default router
