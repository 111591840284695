export default {
    saveTokenStorage(key,value){
        window.localStorage.setItem(key,value);
    },
    getTokenStorage(key){
        return window.localStorage.getItem(key);
    },
    saveSessionObject(key,value){
        window.sessionStorage.setItem(key,JSON.stringify(value));
    },
    getSessionObject(key){
        return JSON.parse(window.sessionStorage.getItem(key));
    },
    remove(key){
        return window.sessionStorage.removeItem(key);
    },
    localremove(key){
        localStorage.removeItem(key);
    }
}