const schedule = {
    namespaced: true,
    state:{
        scheduletime:[6.0,7.0,12.0,18.0,22.0],
        scheduletimetemp: [6.0,7.0,12.0,18.0,22.0],
        schedulemedinfolist: [],
        //是否由审核提交过来的
        iscommit: false,
        scheduleprescription:{},
        singlemedlist: [],
        druginterlist: [],
        timetype: "30",
        schedulemedhit: "",
    },
    getters:{

    },
    mutations:{
        setScheduletime(state,value){
            state.scheduletime = value;
        },
        setScheduletimetemp(state,value){
            state.scheduletimetemp = value;
        },
        setScheduleMedinfoList(state,value){
            state.schedulemedinfolist = value;
        },
        setIsCommit(state,value){
            state.iscommit = value;
        },
        setSchedulePrescription(state,value){
            state.scheduleprescription = value;
        },
        setSingleMedList(state,value){
            state.singlemedlist = value;
        },
        setDrugInterList(state,value){
            state.druginterlist = value;
        },
        setTimeType(state,value){
            state.timetype = value;
        },
        setScheduleMedhit(state,value){
            state.schedulemedhit = value;
        }
    },
    actions:{

    }
}
export default schedule